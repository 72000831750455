import {
  IconButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogProps as MuiDialogProps,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  Slide,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close as CloseIcon } from '@material-ui/icons';
import cx from 'classnames';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Button } from '@shared/components/button-new';
import { ButtonsProps } from '@shared/components/confirmation';
import { Flex } from '@shared/components/flex';
import { ButtonColour, ButtonSize } from '@shared/types/common/button';

import { styles } from './Dialog.styles';

export enum DialogTheme {
  legacy = 'legacy',
  new = 'new',
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export interface DialogHeadingProps extends Omit<MuiDialogTitleProps, 'classes'> {
  heading?: React.ReactNode;
  withCloseButton?: boolean;
  onClose?: () => any;
}

export interface DialogProps extends WithStyles<typeof styles>, Omit<MuiDialogProps, 'classes'> {
  actionNode?: React.ReactElement;
  buttonProps?: ButtonsProps;
  error?: string;
  headingProps?: DialogHeadingProps;
  theme?: DialogTheme;
  withFooter?: boolean;
  withTransition?: boolean;
}

const DialogComponent: React.FC<DialogProps> = ({
  actionNode,
  buttonProps,
  children,
  classes,
  error,
  headingProps,
  open,
  theme = DialogTheme.legacy,
  withFooter = true,
  withTransition = false,
  onClose,
  onSubmit,
  ...otherProps
}) => {
  const handleCancel = () => {
    if (onClose) {
      onClose('', 'backdropClick');
    }
  };

  const handleSubmit = (e: any) => {
    if (onSubmit) {
      onSubmit(e);
    }
  };

  const renderActionButtons = () => {
    if (actionNode) {
      return (
        <MuiDialogActions classes={{ root: classes.actions }}>
          {error && <span className={classes.actionsError}>{error}</span>}
          <Flex justifyContent="space-between" classes={{ root: classes.actionButtons }}>
            {actionNode}
            <div>
              <Button
                text={buttonProps?.cancel?.text || t('cancel')}
                colour={ButtonColour.secondary}
                size={ButtonSize.mediumLegacy}
                onClick={handleCancel}
                classes={{ root: classes.cancel }}
                {...buttonProps?.cancel}
              />
              <Button
                colour={ButtonColour.primary}
                size={ButtonSize.mediumLegacy}
                text={t('apply')}
                onClick={handleSubmit}
                {...buttonProps?.confirm}
              />
            </div>
          </Flex>
        </MuiDialogActions>
      );
    }

    return (
      <MuiDialogActions classes={{ root: classes.actions }}>
        {error && <span className={classes.actionsError}>{error}</span>}
        <Flex justifyContent="space-between" classes={{ root: classes.actionButtons }}>
          <Button
            text={buttonProps?.cancel?.text || t('cancel')}
            colour={ButtonColour.secondary}
            size={ButtonSize.mediumLegacy}
            onClick={handleCancel}
            {...buttonProps?.cancel}
          />
          <Button
            colour={ButtonColour.primary}
            size={ButtonSize.mediumLegacy}
            text={t('apply')}
            onClick={handleSubmit}
            {...buttonProps?.confirm}
          />
        </Flex>
      </MuiDialogActions>
    );
  };

  return (
    <MuiDialog
      {...otherProps}
      open={open}
      {...(withTransition && { TransitionComponent: Transition })}
      onClose={onClose}
      classes={{ paper: cx(classes.root, classes[theme]) }}
    >
      {headingProps && (
        <MuiDialogTitle disableTypography className={classes.titleRoot}>
          <Flex alignItems="center" justifyContent="space-between" classes={{ root: classes.title }}>
            {headingProps.heading && <h1 className={classes.heading}>{headingProps.heading}</h1>}
            {headingProps.withCloseButton && onClose && (
              <IconButton disableRipple className={classes.closeIcon} onClick={handleCancel}>
                <CloseIcon classes={{ root: classes.closeIconIcon }} />
              </IconButton>
            )}
          </Flex>
        </MuiDialogTitle>
      )}

      {children}
      {withFooter && renderActionButtons()}
    </MuiDialog>
  );
};

export const Dialog = withStyles(styles)(DialogComponent);
